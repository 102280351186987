<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<div class="row align-items-center justify-content-start">
						Vehicle documents
					</div>
				</div>
				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="tableData"
					:fields="fields"
					:current-page="currentPage"
					:busy="loading"
					>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(type)="data">
						{{ data?.item?.document_type ?? 'N/A' }}
					</template>

					<template #cell(category)="data">
						{{ data?.item?.document_category ?? 'N/A' }}
					</template>
					<template #cell(document)="data">
						<b-button
							variant="primary"
							@click="handleDocViewing(data?.item?.document_url)"
							size="sm"
							>View</b-button
							>
					</template>

					<template #cell(expiry_date)="data">
						{{ data?.item?.expiry_date ?? 'N/A' }}
					</template>

					<template #cell(status)="data">
						<div v-if="editRow === data.index">
							<b-row v-if="selectedOption" class="d-lg-flex flex-wrap mb-0">
								<v-select
									v-model="selectedOption"
									class="form-group"
									:options="statusList"
									label="label"
									style="width: 70%"
									placeholder="select status"
									:reduce="(option) => option.status"
									>
								</v-select>
								<div class="d-flex justify-content-between align-items-center">
									<button
										size="sm"
										class="btn btn-sm btn-primary mr-2"
										:disabled="savingUpdate"
										@click="saveUpdate"
										>
										{{ savingUpdate ? 'Saving...' : 'Save' }}
									</button>
									<b-button
										style="background-color: #4848ed"
										size="sm"
										:disabled="savingUpdate"
										@click="cancelUpdate"
										>
										Cancel
									</b-button>
								</div>
							</b-row>
							<b-row v-if="!selectedOption" class="d-lg-flex flex-wrap mb-0">
								<v-select
									v-model="selectedOption"
									class="form-group"
									:options="statusList"
									label="label"
									style="width: 77%"
									placeholder="select status"
									:reduce="(option) => option.status"
									>
								</v-select>
								<div class="d-flex justify-content-between align-items-center">
									<button
										size="sm"
										class="btn btn-sm btn-primary mr-2"
										:disabled="savingUpdate"
										@click="saveUpdate"
										>
										{{ savingUpdate ? 'Saving...' : 'Save' }}
									</button>
									<b-button
										style="background-color: #4848ed"
										size="sm"
										:disabled="savingUpdate"
										@click="cancelUpdate"
										>
										Cancel
									</b-button>
								</div>
							</b-row>
						</div>

						<span v-else>
							<span
								:class="[
									data?.item?.document_status === 'verified'
										? 'text-success'
										: data?.item?.status === 'Unverified'
											? 'text-danger'
											: data?.item?.status === 'Under review'
												? 'text-info'
												: '',
								]"
								v-if="data?.item?.document_status !== null"
								>
								{{ data?.item?.document_status || '' }}</span
								>
							<span v-else>N/A</span>
						</span>
					</template>

					<template #cell(date_created)="data">
						{{ data?.item?.created_at }}
					</template>

					<template #cell(actions)="data">
						<b-button
							style="background-color: #4848ed"
							size="sm"
							v-if="editRow !== data.index && userHasPermission('vehicles010')"
							:disabled="savingUpdate"
							@click="edit(data)"
							>
							Update
						</b-button> 
					</template>
				</b-table>

				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="pageSize"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import tableView from '@/mixins/tableview'
import { extractErrorMessage } from '@/utils/helpers'
import moment from 'moment'
// import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'

export default {
  name: 'PartnerDrivers',
  mixins: [tableView],
  props: {
    vehicleId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      partnerId: null,
      viewDoc: false,
      selected: null,
      editRow: -1,
      savingUpdate: false,
      selectedOption: null,
      tableData: [],
      statusList: [
        {
          status: 'completed',
          label: 'Approved'
        },
        {
          status: 'failed',
          label: 'Failed'
        }
      ],
      loading: false,
      errorLoading: false,
      updateAttempt: false,
      fields: [
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'category',
          label: 'Category'
        },
        {
          key: 'document',
          label: 'Document'
        },
        {
          key: 'expiry_date',
          label: 'Expiry date'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'date_created',
          label: 'Date created'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      showAddVehicleModal: false
    }
  },
  watch: {
    currentPage (value) {
      if (value) {
        this.fetchVehicleDocuments(false, this.partnerId)
      }
    }
  },
  created () {
    this.fetchVehicle()
    // this.tableData = []
  },
  methods: {
    async fetchVehicle () {
      try {
        this.loading = true
        const result = await this.axios.get(`/v1/vehicles/${this.vehicleId}`)
        this.partnerId = result?.data?.partner_id
        await this.fetchVehicleDocuments(false, this.partnerId)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    handleDocViewing (value) {
      this.$zoom(value, {
        allowZoom: true,
        autoScale: true,
        closeOnClickModal: false
      })
    },
    edit (data) {
      this.editRow = data.index
      this.selectedOption = data.item.status || null
    },

    async saveUpdate () {
      const data = this.tableData[this.editRow]
      if (this.selectedOption == null) {
        this.$toastr.w('Please select a status update', 'Warning')
        return
      }
      data.status = this.selectedOption
      this.savingChange = true
      this.savingUpdate = true
      try {
        await this.axios.patch(
          `/v1/partners/${this.partnerId}/vehicle-documents/${data.id}`,
          {
            status: data.status
          }
        )
        this.$toastr.s('Document status was updated successfully', 'Success')
        this.fetchVehicleDocuments(false, this.partnerId)
      } catch (error) {
        const errorMessage = extractErrorMessage(
          error,
          'An error occurred. Please try again later'
        )
        this.$toastr.e(errorMessage, 'Error')
      } finally {
        this.savingUpdate = false
      }
      this.editRow = -1
      this.savingUpdate = false
    },

    cancelUpdate () {
      this.editRow = -1
      this.selectedOption = null
    },

    async fetchVehicleDocuments (showLoader = true, partnerId) {
      if (showLoader) this.loading = true
      try {
        const response = await this.axios.get(
          `v1/partners/${partnerId}/vehicle/${this.vehicleId}/vehicle-documents`
        )
        let formattedDocuments = response.data.vehicleDocuments.map((doc) => {
          if (doc?.documents?.length) {
            return {
              ...doc?.documents,
              document_category: 'vehicle documents',
              document_type: doc?.documents[0]?.document_type ?? null,
              document_status:
                doc?.documents[0]?.status === 'completed'
                  ? 'Approved'
                  : doc?.documents[0]?.status === 'failed'
                    ? 'Failed'
                    : doc?.documents[0]?.status === 'submitted'
                      ? 'Submitted'
                      : '',
              document_url: doc?.documents[0].files[0] ?? null,
              created_at:
                moment(doc?.documents[0]?.updated_at).format('YYYY-MM-DD') ??
                null,
              expiry_date:
                moment(doc?.documents[0]?.expiry_date)?.format('YYYY-MM-DD') ??
                null,
              id: doc?.documents[0]?.id ?? null
            }
          } else {
            return null
          }
        })
        formattedDocuments = formattedDocuments.reduce((acc, curr, index) => {
          if (curr) {
            acc[index] = curr
          }
          return acc
        }, [])
        this.tableData = formattedDocuments
      } catch (error) {
        this.$toastr.e('Something went wrong!', 'Error')
        this.loading = false
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
</style>
